<template>
    <v-container fluid>
        <v-toolbar elevation="1" dark color="green">
            <v-btn icon @click="$router.push({ path: '/rhumanos' })">
                <v-icon>
                    mdi-view-grid
                </v-icon>
            </v-btn>

            <v-toolbar-title>
                Reportes
            </v-toolbar-title>

            <v-spacer/>
        </v-toolbar>

        <v-sheet elevation="2">
            <v-row no-gutters>
                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                        <v-treeview
                            class="pa-2"
                            :items="reportes"
                            open-on-click
                            activatable
                            hoverable
                            selected-color
                            color="green"
                            :active.sync="reporteActivo"
                        />
                </v-col>

                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <v-sheet elevation="1">
                        <reporte-101
                            v-if="reporteActivo[0]==101"
                        />
                    </v-sheet>
                </v-col>
            </v-row>
        </v-sheet>


    </v-container>
</template>

<script>
import Reporte101 from '@/components/rhumanos/reportes/reporte101'
export default {
    components:{
        'reporte-101':Reporte101,
    },
    data:()=>({
        reporteActivo:[],
        reportes:[
            {
                id:10,
                name:'Salud',
                children:[
                    {
                        id:101,
                        name: 'Todos por mes'
                    },
                ]
            }
        ]
    }),
    methods:{
        cargaReporte(){
            console.log(this.reporteSeleccionado)
        }
    },
    watch:{
        
        reporteActivo(){
            //console.log(this.reporteActivo[0])
            switch(this.reporteActivo[0]){
                case 101:{
                    console.log('Cargando el primer valor')
                    break
                }
            }
        },
    }
}
</script>

<style>

</style>